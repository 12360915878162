import React, { Component } from 'react'
import axios from 'axios';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Rodo = () => {
    return (
        <p>Wyrażam zgodę na przetwarzanie przez Biuro Rachunkowe “RATIONALIS” mgr Krzysztof Woryna moich danych osobowych w celach marketingowych, w tym na profilowanie w tych celach. Wyrażona zgoda może być odwołana w każdym czasie. W celu przedstawienia przez Biuro Rachunkowe “RATIONALIS” mgr Krzysztof Woryna indywidualnej oferty produktów i usług drogą telefoniczną wyrażenie zgody jest niezbędne.</p>
    );
};

class FormComponent extends Component {

    componentDidMount() {
        if (localStorage.getItem('mail-sent') === 'true') {
            this.setState({ mailSent: true })
        }
    }

    constructor(props) {
        super(props);

        const getUrl = window.location;
        const host = getUrl.hostname === 'localhost' ? getUrl.hostname : getUrl.host;
        const baseUrl = `${getUrl.protocol}//${host}`;

        this.state = {
            baseURL: baseUrl,
            name: '',
            email: '',
            phone: '',
            message: '',
            rodo: false,
            formErrors: {
                name: [],
                email: [],
                phone: [],
                message: [],
                rodo: []
            },
            captchaToken: '',
            mailSent: false,
            rodoEnabled: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    verifyCaptcha(token) {
        this.setState({ captchaToken: token });
        axios.post(`${this.state.baseURL}/mailing/recaptcha.php`,
            {
                captchaToken: token
            }, {
                crossdomain: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(response => {
                if (response.data.success) {
                    localStorage.setItem('captcha-token', response.data.token);
                    this.setState({ captchaToken: response.data.token })
                }
            })
            .catch(error => {
                localStorage.setItem('captcha-token', '');
                console.error(error);
            })

    }

    handleChange(event) {

        const { name, value, type } = event.target;
        if (type === 'checkbox')
            this.setState({ rodo: event.target.checked });
        else
            this.setState(() => ({
                [name]: value.trim()
            }));
    }

    handleSubmit(event) {
        event.preventDefault();
        const { name, email, phone, message, rodo } = this.state;

        let toValidate = { name, email, phone, message, rodo };
        const validationResults = this.validate(toValidate);
        if (!validationResults.success) {
            this.setState({
                formErrors: validationResults.errors
            });
        } else {

            axios.post(`${this.state.baseURL}/mailing/index.php`, {
                name,
                email, phone, message, token: this.state.captchaToken
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.success) {
                        const MySwal = withReactContent(Swal);
                        MySwal.fire({
                            type: 'success',
                            title: 'Dziękujemy!',
                            text: 'Twoja wiadomość została wysłana!',
                        });

                        localStorage.setItem('mail-sent', 'true');
                        this.setState({ mailSent: true })

                    } else {
                        const MySwal = withReactContent(Swal);
                        MySwal.fire({
                            type: 'error',
                            title: 'Przepraszamy...',
                            text: 'Coś poszło nie tak, spróbuj ponownie później.',
                        });
                        console.log(response.data);
                    }
                })
                .catch(error => {
                    const MySwal = withReactContent(Swal);
                    MySwal.fire({
                        type: 'error',
                        title: 'Przepraszamy...',
                        text: 'Coś poszło nie tak, spróbuj ponownie później.',
                    });
                    console.error(error);
                });
        }
    }

    validate(_values) {
        let errorsArray = {
            name: [],
            phone: [],
            email: [],
            message: [],
            rodo: []
        };
        let status = true;

        let temporary = _values.phone;
        temporary = temporary.replace(/ /g, '').replace('+', '').replace(/-/g, '');
        if (_values.phone.length > 0) {

            if (temporary.length < 9 || temporary.length > 15) {
                errorsArray.phone.push('Twój numer telefonu musi mieć 9-15 znaków');
                status = false;
            }

            if (!(!isNaN(parseFloat(temporary)) && isFinite(temporary))) {
                errorsArray.phone.push('Numer telefonu powinien zawierać liczby 0-9 oraz ewentualnie znak "+"');
                status = false;
            }

        }

        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(_values.email).toLowerCase())) {
            errorsArray.email.push('Wprowadź poprawny adres email');
            status = false;
        }

        if (!_values.rodo) {
            errorsArray.rodo.push('Musisz wyrazić zgodę na przetwarzanie danych, aby wysłać formularz.');
            status = false;
        }

        return {
            success: status,
            errors: errorsArray
        }
    }

    renderForm() {
        return (
            <div className="form-container">
                <p>Masz pytanie? Napisz do nas:</p>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        <input type="text" name="name"
                               className={this.state.formErrors.name.length > 0 ? 'error' : ''}
                               placeholder="Imię i nazwisko"
                               onChange={this.handleChange} />
                        <dl className="errors" id="name">
                            {this.state.formErrors.name.map((error, index) =>
                                <dd key={`error-${index}`}>{error}</dd>
                            )}
                        </dl>
                    </label>
                    <label>
                        <input type="email" name="email"
                               className={this.state.formErrors.email.length > 0 ? 'error' : ''}
                               placeholder="Email*"
                               onChange={this.handleChange} required />
                        <dl className="errors" id="email">
                            {this.state.formErrors.email.map((error, index) => <dd key={`error-${index}`}>{error}</dd>)}
                        </dl>
                    </label>
                    <label>
                        <input type="tel" name="phone"
                               className={this.state.formErrors.phone.length > 0 ? 'error' : ''}
                               placeholder="Telefon"
                               onChange={this.handleChange} />
                        <dl className="errors" id="phone">
                            {this.state.formErrors.phone.map((error, index) => <dd key={`error-${index}`}>{error}</dd>)}
                        </dl>
                    </label>
                    <label>
                            <textarea name="message" cols="40" rows="10"
                                      className={this.state.formErrors.message.length > 0 ? 'error' : ''}
                                      placeholder="Wiadomość"
                                      onChange={this.handleChange} />
                        <dl className="errors" id="message">
                            {this.state.formErrors.message.map((error, index) =>
                                <dd key={`error-${index}`}>{error}</dd>)}
                        </dl>
                    </label>
                    <div className="checkbox-container">
                        <input type="checkbox" name="rodo" id="rodo" onChange={this.handleChange} required />
                        <label htmlFor="rodo" />
                        {!this.state.rodoEnabled && <span>
                            Wyrażam zgodę na przetwarzanie przez Biuro Rachunkowe “RATIONALIS” mgr Krzysztof Woryna moich danych osobowych. <span className="link" onClick={() => this.setState({ rodoEnabled: !this.state.rodoEnabled })}>Więcej...</span>
                        </span>}
                        {this.state.rodoEnabled && <Rodo />}
                        <dl className="errors" id="rodo">
                            {this.state.formErrors.rodo.map((error, index) => <dd key={`error-${index}`}>{error}</dd>)}
                        </dl>
                    </div>
                    <label>
                        <input type="submit" value="Wyślij" />
                    </label>
                    <div className="recaptcha">
                        <p>Ta strona jest chroniona przez reCaptcha od Google: <br />
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Polityka prywatności</a><span className="separator" />
                            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Warunki usługi</a>
                        </p>
                        <GoogleReCaptcha onVerify={token => this.verifyCaptcha(token)} />
                    </div>
                </form>
            </div>
        )
    }

    renderMailSent() {
        return (
            <>
                <div className="sent-container">
                    <div className="icon-container">
                        <i className="pe-7s-check" />
                    </div>
                    <div className="title">Dziękujemy!</div>
                    <div className="text">Twoja wiadomość już dotarła,<br />niedługo otrzymasz odpowiedź.</div>
                </div>
            </>
        )
    }

    render() {
        // if (this.state.mailSent) {
        //     return this.renderMailSent();
        // }
        return this.renderForm();
    }
}

export default FormComponent;