import React, {Component, useEffect, useState} from 'react'
import axios from 'axios';

import logo from '../assets/images/logo2.png'

const Logo = () => {
    const [isActive, setActive] = useState(false);
    const [visitors, setVisitors] = useState(0);

    useEffect(() => {
        const getUrl = window.location;
        const host = getUrl.hostname === 'localhost' ? getUrl.hostname : getUrl.host;
        const baseUrl = `${getUrl.protocol}//${host}`;
        const storeVisitors = localStorage.getItem('rationalis:visitors');

        if (storeVisitors && storeVisitors === '1') {
            axios.get(`${baseUrl}/mailing/visitors.php`)
                .then(function (response) {
                    const {visitors: visitorsData} = response.data;
                    setVisitors(visitorsData);
                    setActive(true);
                })
                .catch(error => console.log('Error:', error))
        } else {
            axios.post(`${baseUrl}/mailing/visitors.php`, {}, {headers: {'Content-Type': 'application/json'}}
            )
                .then(response => {
                    const {visitors: visitorsData} = response.data;
                    localStorage.setItem('rationalis:visitors', '1');
                    setVisitors(visitorsData);
                    setActive(true);
                })
                .catch(error => console.log('Error:', error))
        }

    }, []);

    return (
        <img
            src={logo}
            alt="Biuro Rachunkowe RATIONALIS mgr Krzysztof Woryna"
            className={`footer-logo${isActive ? ' active' : ''}`}
            onDoubleClick={() => alert(visitors)}
        />
    );
};


class FooterComponent extends Component {

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 content-container">
                            <Logo />
                            <span>Biuro Rachunkowe RATIONALIS mgr Krzysztof Woryna. <br />&copy; 2018 - {(new Date().getFullYear())} Wszystkie prawa zastrzeżone.</span>
                            <span className="creator">Realizacja: Marcin Słaboń</span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterComponent;