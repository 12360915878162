import React, {Component} from 'react';
import '../App.scss';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import {Helmet} from "react-helmet/es/Helmet";

class DefaultLayout extends Component {
    componentDidMount() {

    }

    render(props) {
        const data = {
            title: 'Biuro Rachunkowe “RATIONALIS” mgr Krzysztof Woryna',
            defaultTitle: 'Biuro Rachunkowe “RATIONALIS” mgr Krzysztof Woryna',
            defaultDescription: 'Ofertę naszego biura adresujemy do ludzi. którzy pragną skoncentrować się na dynamicznym rozwoju firmy i nie chcą tracić energii na "papierkową robotę". Ślęczenie nad rachunkami, fakturami i innymi dokumentami księgowymi zostawcie Państwo profesjonalistom.',
            logo: process.env.PUBLIC_URL + '/favicon.png',
            author: 'Marcin Słaboń',
            url: 'https://rationalis.com.pl',
            legalName: 'Krzysztof Woryna',
            language: 'pl',
            socialLinks: {
                facebook: 'https://www.facebook.com/Biuro-Rachunkowe-Rationalis-mgr-Krzysztof-Woryna-1156926431112430/',
                github: 'https://github.com/tysian',
            },
            themeColor: '#F48120',
            backgroundColor: '#FFF',
            faviconPath: process.env.PUBLIC_URL + '/favicon.png',
            address: {
                city: 'Pawłowice',
                region: 'Silesian',
                country: 'Poland',
                zipCode: '43-250',
            },
            contact: {
                email: 'biuro@rationalis.com.pl',
                phone: '324724435',
            },
            foundingDate: '2018',
            keywords: 'biuro,rachunkowe,rationalis,mgr,Krzysztof,Woryna,tanie,kompleksowe,usługi,wieloletnie,doświadczenie,łatwy,kontakt,faktury,księgowość,spółek z o.o.,spółek komandytowych,fundacji,stowarzyszeń,spółek jawnych cywilnych,osób fizycznych,działalność gospodarcza'
        };
        const structuredDataOrganization = `{ 
            "@context": "http://schema.org",
            "@type": "Organization",
            "legalName": "${data.legalName}",
            "url": "${data.url}",
            "logo": "${data.logo}",
            "foundingDate": "${data.foundingDate}",
            "founders": [{
                "@type": "Person",
                "name": "${data.legalName}"
            }],
            "contactPoint": [{
                "@type": "ContactPoint",
                "email": "${data.contact.email}",
                "telephone": "${data.contact.phone}",
                "contactType": "customer service"
            }],
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "${data.address.city}",
                "addressRegion": "${data.address.region}",
                "addressCountry": "${data.address.country}",
                "postalCode": "${data.address.zipCode}"
            },
            "sameAs": [
                "${data.socialLinks.facebook}",
                "${data.socialLinks.twitter}",
                "${data.socialLinks.linkedin}",
                "${data.socialLinks.instagram}",
            ]
          }`;
        return (
            <GoogleReCaptchaProvider reCaptchaKey="6Lfka_sUAAAAAAAWHK1Jv3yxtMpB-WTyYVeXnYtd">
                <Helmet>
                    <meta name="description" content={data.defaultDescription} />
                    <meta name="image" content={process.env.PUBLIC_URL + '/favicon.png'} />
                    <meta name="keywords" content={data.keywords} />

                    <meta property="og:url" content={`${data.url}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={data.defaultTitle} />
                    <meta property="og:description" content={data.defaultTitle} />
                    <meta property="og:image" content={process.env.PUBLIC_URL + '/favicon.png'} />

                    <meta name="theme-color" content={data.themeColor} />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={data.title} />
                    <meta name="twitter:description" content={data.defaultTitle} />
                    <meta name="twitter:image:src" content={process.env.PUBLIC_URL + '/favicon.png'} />
                    <script type="application/ld+json">
                        {structuredDataOrganization}
                    </script>
                    <link rel="publisher" href={data.socialLinks.github} />

                    <title>{data.title}</title>
                    <html lang={data.language} dir="ltr" />
                </Helmet>
                <HeaderComponent />
                {this.props.children}
                <FooterComponent />
            </GoogleReCaptchaProvider>
        )
    }
}

export default DefaultLayout;