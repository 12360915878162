import React, {Component} from 'react';
import {Element} from 'react-scroll'
import Obfuscate from 'react-obfuscate'

import DefaultLayout from "../layouts/default-layout";
import TilesSwiper from '../components/swiper';
import FormComponent from '../components/form';

import heroImage from '../assets/images/daydreaming.jpg';
import deskLaptopImage from '../assets/images/desk_laptop.jpg';

import {ReactComponent as EmailIcon} from '../assets/images/mail-open-file.svg';
import {ReactComponent as PhoneIcon} from '../assets/images/call.svg'
import {ReactComponent as FacebookIcon} from '../assets/images/facebook.svg'
import {ReactComponent as LocationIcon} from '../assets/images/map-marker.svg'
import {ReactComponent as RightArrow} from '../assets/images/right-arrow.svg'
import {ReactComponent as Users} from '../assets/images/users.svg';
import {ReactComponent as Science} from '../assets/images/science.svg';
import {ReactComponent as Display} from '../assets/images/display1.svg';
import {ReactComponent as Timer} from '../assets/images/timer.svg';
import {ReactComponent as Medal} from '../assets/images/medal.svg';
import {ReactComponent as Study} from '../assets/images/study.svg';
import {ReactComponent as Headphones} from '../assets/images/headphones.svg';
import {ReactComponent as Safe} from '../assets/images/safe.svg';
import {ReactComponent as Car} from '../assets/images/car.svg';
import {ReactComponent as Clock} from '../assets/images/clock.svg';

const offerSlides = [
    {
        icon: <RightArrow />,
        title: 'Księgi handlowe',
        description: false
    },
    {
        icon: <RightArrow />,
        title: 'Podatkowa księga przychodów i rozchodów',
        description: false
    },
    {
        icon: <RightArrow />,
        title: 'Ewidencja ryczałtowa',
        description: false
    },
    {
        icon: <RightArrow />,
        title: 'Kadry i płace',
        description: false
    },
    {
        icon: <RightArrow />,
        title: 'Rozliczenia z ZUS',
        description: false
    },
    {
        icon: <RightArrow />,
        title: 'Ewidencje dla potrzeb podatku VAT',
        description: false
    },
    {
        icon: <RightArrow />,
        title: 'Inne zlecenia z zakresu księgowości i podatków',
        description: false
    },


];
const defaultSlides = [
    {
        icon: <Users />,
        title: 'Kadra',
        description: 'Firma zatrudnia pracowników z bogatym doświadczeniem w branży rachunkowo-księgowej.'
    },
    {
        icon: <Science />,
        title: 'Profesjonalizm',
        description: 'Profesjonalne, indywidualne i szczegółowe podejście do każdego klienta.'
    },
    {
        icon: <Display />,
        title: 'Innowacyjność',
        description: 'Pracujemy na najnowszym dostępnym oprogramowaniu.'
    },
    {
        icon: <Timer />,
        title: 'Doświadczenie w branży',
        description: 'Jesteśmy na rynku od 2008 r., a właściciel doświadczenie zbierał od roku 1999.'
    },
    {
        icon: <Medal />,
        title: 'Zaufanie od klientów',
        description: 'Współpracę z klientami opieramy na obustronnym zaufaniu i partnerskim charakterze relacji. Wiele firm współpracuje z nami od początku założenia naszego biura.'
    },
    {
        icon: <Study />,
        title: 'Wiedza',
        description: 'Stale podnosimy swoją wiedzę, aby zapewnić świadczenie usług na najwyższym poziomie.'
    },
];

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    componentDidMount() {
        this.handleMail();
    }

    componentWillUnmount() {

    }

    handleMail() {
        this.setState({email: 'biuro@rationalis.com.pl'});
    }

    render() {
        return (
            <DefaultLayout>
                <main className="homepage">
                    <section className="hero background-image" style={{backgroundImage: `url('${heroImage}')`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 content-box">
                                    <h1 className="title">Biuro Rachunkowe <span className="orange">“RATIONALIS”</span>
                                        <br />mgr Krzysztof Woryna</h1>
                                    <p className="description">Ofertę naszego biura adresujemy do ludzi, którzy pragną skoncentrować się na dynamicznym rozwoju firmy i&nbsp;nie chcą tracić energii na "papierkową robotę". Ślęczenie nad rachunkami, fakturami i&nbsp;innymi dokumentami księgowymi zostawcie Państwo profesjonalistom.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Element name="oferta" className="element">
                        <section className="slider">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="section-title">Oferta</h2>
                                        <p>Prowadzimy księgowość spółek z o.o., spółek komandytowych, fundacji, stowarzyszeń, spółek jawnych, cywilnych i osób fizycznych prowadzących działalność gospodarczą.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-10 col-xl-12 offset-md-1 offset-xl-0">
                                        <TilesSwiper slides={offerSlides} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="buttons-container">
                                            <a
                                                href="https://forms.gle/5PmG4RjWTrj1azdcA"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                                className="btn"
                                            >Wyceń usługę</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Element>
                    <Element name="dlaczego-my" className="element">
                        <section className="skills background-image" style={{backgroundImage: `url(${deskLaptopImage})`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-7 title-container">
                                        <h2 className="section-title dark">Dlaczego my</h2>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-5 icons-container">
                                        <ul className="icons-list">
                                            <li>
                                                <Headphones />
                                                <span>Dobry i łatwy kontakt</span>
                                            </li>
                                            <li>
                                                <Users />
                                                <span>Doświadczeni pracownicy</span>
                                            </li>
                                            <li>
                                                <Safe />
                                                <span>Bezpieczeństwo danych</span>
                                            </li>
                                            <li>
                                                <Car />
                                                <span>Dojazd do klienta po dokumenty</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Element>
                    <Element name="co-nas-wyroznia" className="element">
                        <section className="slider">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="section-title">Co nas wyróżnia?</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-10 col-xl-12 offset-md-1 offset-xl-0">
                                        <TilesSwiper slides={defaultSlides} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Element>
                    <hr style={{borderColor: '#F48120', borderWidth: 8, margin: 0}} />
                    <Element name="kontakt" className="element">
                        <section className="contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="section-title">Kontakt</h2>
                                        <p>Biuro Rachunkowe "RATIONALIS" mgr Krzysztof Woryna NIP 6332037025</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-7 title-container">
                                        <ul className="contact-list">
                                            <li>
                                                <EmailIcon />
                                                <Obfuscate email="biuro@rationalis.com.pl" />
                                            </li>
                                            <li>
                                                <PhoneIcon />
                                                <Obfuscate tel="32 472 44 35" />
                                            </li>
                                            <li>
                                                <FacebookIcon />
                                                <a href="https://www.facebook.com/Biuro-Rachunkowe-Rationalis-mgr-Krzysztof-Woryna-1156926431112430/" target="_blank" rel="noopener noreferrer">Rationalis</a>
                                            </li>
                                            <li>
                                                <Clock />
                                                <span>Czynne: Pn-Pt, 8:00 - 16:00</span>
                                            </li>
                                            <li>
                                                <LocationIcon />
                                                <span>ul. Zjednoczenia 62a <br /> 43-250 Pawłowice</span>
                                            </li>
                                            <li>
                                                <Safe />
                                                <span>60 1050 1605 1000 0023 2561 8656</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-5 title-container">
                                        <div className="contact-form">
                                            <FormComponent />
                                        </div>
                                    </div>

                                    <div className="col-12 map-container">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.5992592223647!2d18.717138315882867!3d49.96261943027517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716adbb537d399f%3A0xa2fa0d095dd633a9!2sZjednoczenia+62A%2C+43-250+Paw%C5%82owice!5e0!3m2!1spl!2spl!4v1555154618651!5m2!1spl!2spl" width="600" height="450" style={{border: 0}} allowFullScreen title="mapObject" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Element>
                </main>
            </DefaultLayout>
        );
    }
}

export default HomePage;
