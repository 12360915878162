import React, {Component} from 'react'
import {Events, Link, scrollSpy} from 'react-scroll';

import logo from '../assets/images/logo.png';

class HeaderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpened: false,
            windowWidth: 0, windowHeight: 0
        };

        this.menuCollapseToggle = this.menuCollapseToggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.updateWindowDimensions);

        Events.scrollEvent.register('begin', function (to, element) { });
        Events.scrollEvent.register('end', function (to, element) { });
        scrollSpy.update();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    handleScroll() {
        let scrollTop = window.pageYOffset;
        const headerElement = document.querySelector('header');
        if (scrollTop > 120)
            headerElement.classList.add('scrolled');
        else
            headerElement.classList.remove('scrolled');
    }

    menuCollapseToggle() {
        if (this.state.windowWidth < 768) {
            if (this.state.isMenuOpened)
                this.setState({isMenuOpened: false});
            else
                this.setState({isMenuOpened: true});
        }
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    render() {
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-12 header-wrapper">
                            <div className="top-image">
                                <img src={logo} alt="logo"/>
                            </div>
                            <nav id="main-menu" className={this.state.isMenuOpened ? 'open' : ''}>
                                <ul id="menu-menu-1" className="menu">
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-5 nav-item">
                                        <Link activeClass="active" className="nav-link"
                                              to="oferta"
                                              spy={true}
                                              smooth={true}
                                              offset={-55}
                                              duration={500}
                                              onClick={this.menuCollapseToggle}
                                        >Oferta</Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15 nav-item">
                                        <Link activeClass="active" className="nav-link"
                                              to="dlaczego-my"
                                              spy={true}
                                              smooth={true}
                                              offset={-55}
                                              duration={500}
                                              onClick={this.menuCollapseToggle}
                                        >Dlaczego my</Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15 nav-item">
                                        <Link activeClass="active" className="nav-link"
                                              to="co-nas-wyroznia"
                                              spy={true}
                                              smooth={true}
                                              offset={-55}
                                              duration={500}
                                              onClick={this.menuCollapseToggle}
                                        >Co nas wyróżnia?</Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16 nav-item">
                                        <Link activeClass="active" className="nav-link"
                                              to="kontakt"
                                              spy={true}
                                              smooth={true}
                                              offset={-55}
                                              duration={500}
                                              onClick={this.menuCollapseToggle}
                                        >Kontakt</Link>
                                    </li>
                                </ul>
                            </nav>
                            <div className={`hamburger-wrapper${this.state.isMenuOpened ? ' active' : ''}`} onClick={this.menuCollapseToggle}>
                                <div className="hamburger">
                                    <div className="inner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderComponent;