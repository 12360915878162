import React, {useState, useEffect} from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/scss/swiper.scss';
import {Navigation} from 'swiper/dist/js/swiper.esm'
import {ReactComponent as ArrowRight} from "../assets/images/arrow-right.svg";

const TilesSwiper = ({slides}) => {
    const swiperParams = {
        modules: [Navigation],
        containerClass: 'swiper-container swiper-box',
        slidesPerView: 3,
        loop: true,
        spaceBetween: 30,
        breakpoints: {
            1200: {
                slidesPerView: 2,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
            },
            992: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 1,
                noSwiping: true,
                allowSlidePrev: false,
                allowSlideNext: false,
            },
        },
    };

    useEffect(() => {
    }, []);

    const [swiper, updateSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <>
            <Swiper getSwiper={updateSwiper} {...swiperParams}>
                {slides.map((slide, index) => (
                    <div className="single-slide" key={index}>
                        {slide.icon}
                        {slide.title && <h3>{slide.title}</h3>}
                        {slide.description && <p>{slide.description}</p>}
                    </div>
                ))}
            </Swiper>
            <div className="swiper-navigation">
                <div className="swiper-button-prev" onClick={goPrev}>
                    <ArrowRight />
                </div>
                <div className="swiper-button-next" onClick={goNext}>
                    <ArrowRight />
                </div>
            </div>
        </>
    );
};

export default TilesSwiper;